export default [
    {
        id:'4cc89776-8a4b-41fd-9656-51571556901e',
        year:'2024',
        months: '12-08',
        title: '英思集成受邀到岭南师范学院分享集成电路产业主题',
        profile:'2024年12月7日，英思集成公司创始人及总经理郑观东先生受邀到岭南师范学院进行集成电路产业相关主题的分享会。',
        img: '/img/article/1733729080562.jpg',
    },
    {
        id: 'a04cb917-1692-4a5f-9f39-d454ba2116cd',
        year: '2024',
        months: '11-29',
        title: 'T/CAUI-014-2024《集成电路实验教学平台》标准正式发布',
        profile: '2024年11月14日，我司参与制定的T/CAUI-014-2024《集成电路实验教学平台》标准正式在全国团体标准信息平台正式发布。',
        img: '/img/article/1733728678287.jpg',
    },
    {
        id: 'eb69036a-f0d3-4c37-a665-dfba18ea286b',
        year: '2024',
        months: '11-20',
        title: '英思集成亮相第62届中国高等教育博览会',
        profile: '2024年11月15日至17日，第62届中国高等教育博览会在重庆国际博览中心隆重举行。本次博览会以“职普融通·产教融合·科教融汇”为主题，汇聚了近700家企业和1000余所院校，共同探讨高等教育高质量发展的新路径。',
        img: '/img/article/1732255709366.png',
    },
    {
        id: '42581a62-c776-4b10-8037-7a84471017df',
        year: '2024',
        months: '11-20',
        title: '苏电院-英思‌集成电路产教融合实训基地揭牌仪式圆满举行',
        profile: '2024年11月13日，江苏电子信息职业学院与珠海市英思集成电路设计有限公司共同举办的集成电路产教融合实训基地揭牌仪式在学院隆重举行。',
        img: '/img/article/1732254953557.jpg',
    },
    {
        id: '0a1929c9-cc95-4eb4-95b6-cb2e9678c2ea',
        year: '2024',
        months: '11-05',
        title: '英思集成受邀参加集成电路产业主题分享',
        profile: '2024年11月4日，公司创始人郑观东先生受邀到珠海市台港澳事务局分享新质生产力有关集成电路产业的相关主题，并就珠海加强与台港澳地区的新质生产力合作交流看法。',
        img: '/img/article/1733727184976.jpg',
    },
    {
        id: '9a94b956-7ed8-11ef-a273-033fd029655f',
        year: '2024',
        months: '7-26',
        title: '英思集成携手广东省电子学会共建工程师专业水平评价新标准',
        profile: '2024年7月26日，广东省电子学会与珠海市英思集成电路设计有限公司签署《集成电路产业水平评价合作协议》。',
        img: '/img/article/1727616880574.jpg',
    },
    {
        id: '9a94b958-7ed8-11ef-a273-033fd029655f',
        year: '2024',
        months: '6-21',
        title: '英思芯学V2.0重磅来袭！',
        profile: '英思芯学结合客户过去一年的实践教学经验，于今天正式发布英思芯学实践系统的V2.0版本。',
        img: '/img/article/2-8.jpg',
    },
    {
        id: '9a94b957-7ed8-11ef-a273-033fd029655f',
        year: '2024',
        months: '5-27',
        title: '暨芯研究院—英思集成电路产学研创新基地正式运营！',
        profile: '2024年5月25日，暨芯研究院—英思集成电路产学研创新基地成立仪式在浙江省湖州市吴江区太湖实验室举行。',
        img: '/img/article/3-1.jpg',
    },
    {
        id: '5188258f-7ca7-11ef-a273-033fd029655f',
        year: '2024',
        months: '4-23',
        title: '让学校接轨产业：与广东工业大学实验教学部的实践教学交流',
        profile: '4月22日，珠海市英思集成电路设计有限公司参与了广东省科学院珠海产业技术研究院党支部与广东工业大学实验教学部大学物理实验中心党支部的支部共建活动。',
        img: '/img/article/5-1.jpg',
    },
    {
        id: '51882590-7ca7-11ef-a273-033fd029655f',
        year: '2024',
        months: '4-15',
        title: '暨南大学-英思集成电路产教融合实践教学基地正式开展教学',
        profile: '4月15日，由暨南大学信息科学技术学院电子工程系与珠海市英思集成电路设计有限公司共同建设的集成电路产教融合实践教学基地正式开展集成电路设计教学。',
        img: '/img/article/4-1.jpg',
    },
]